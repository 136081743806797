<template>
  <div class="page">
    <div class="banner">
      <img src="./img/banner2.png" alt="" />
    </div>
    <div class="list">
      <div
        class="item"
        @click="toPath(item)"
        v-for="(item, index) in serviceGuideList"
        :key="index"
      >
        <img :src="item.imgUrl" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { serviceGuideList } from "./map.js";
export default {
  name: "lawServiceGuide",
  data() {
    return {
      serviceGuideList,
    };
  },
  methods: {
    toPath(item) {
      this.$router.push({
        name: "lawServiceGuideDetail",
        query: {
          titleName: item.name,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  .banner {
    width: 100%;
    height: 348px;
    img {
      width: 100%;
    }
  }
  .list {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -156px;
    .item {
      width: 334px;
      height: 156px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 226, 226, 0.5);
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      box-sizing: border-box;
      border-radius: 8px;
      img {
        width: 88px;
        margin-right: 20px;
      }
      .name {
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
